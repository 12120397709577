
.alarmDetailed {
  background-color: #fafafa;
  text-align: left;
  padding: 14px;
  border-bottom: 2px solid  $gray-300;
  table {
    min-width: 100%;
    overflow-x: auto;
    font-size: 0.8rem;
    font-weight: 300;
    color: $gray-900;
    text-align: left;
  }
  tr {
    line-height: 1.8rem;
    padding: 0;
  }
  tbody tr:nth-child(even) {
    //background-color: #f8f8f8;
  }
  td {
    font-size: 0.95rem;
    padding-left: 1rem;
    padding-top: 0.3rem;
    padding-bottom: calc(0.3rem - 1px);
    //background-color: #eaeaea;
  }
  thead {
    line-height: 1.6rem;
    color: $gray-700;

    th {
    font-size: 0.94rem;
    font-weight: 300;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    padding-top: 0.3rem;
    padding-bottom: calc(0.3rem - 1px);
    }
  }
}

.margin {
  margin-right: 10px;
  margin-top: 3px;
}
.alarmTimeline {
  font-size: 0.94rem;
}
.alarmRow {
  margin:0px;
  background-color: #fff;
  padding: 10px;
}
