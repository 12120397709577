//
// nav.scss
//

.nav-tabs, .nav-pills {
  > li {
    > a {
      color: $gray-700;
      font-weight: $font-weight-semibold;
    }
  }
}

.nav-pills {
  > a {
    color: $gray-700;
    font-weight: $font-weight-semibold;
  }
}


//Navtab custom
.navtab-bg {
  li {
    > a {
      background-color: $gray-200;
      margin: 0 5px;
    }
  }
}


// Nav bordered

.nav-bordered {
  border-bottom: 2px solid rgba($gray-600, 0.2) !important;

  .nav-item {
    margin-bottom: -2px;
  }

  li {
    a {
      border: 0 !important;
      padding: 10px 20px !important;
    }
  }

  a.active {
    border-bottom: 2px solid $primary !important;
  }
}

.tab-content {
  padding: 20px 0 0 0;
}

.ui.secondary.pointing.menu {
  font-size: 0.9rem !important;
  color: $gray-700 !important;
  margin-bottom: 10px !important;
  border-color: transparent !important;

  .item {
    border: 0 !important;
    border-bottom: 2px solid transparent !important;
  }

  .item.active {
    border: 0 !important;
    border-bottom: 2px solid $primary !important;
  }
}
