
//
// breadcrumb.scss
//


// Breadcrumb item arrow
.breadcrumb-item {
    font-size: 0.95rem;
    font-weight: 500;
    > a {
        color: $sem-turq-blue;
    }
    +.breadcrumb-item {
        &::before {
            font-family: "Material Design Icons";
        }
    }
}

.breadcrumb-item.active {
  > a {
    color: inherit;
  }
}
