
.vtl {
    /* Relative is necessary to properly position the child elements within */
    position: relative;
    /* More space for the vertical line to the left */
    padding: 0px 0px 0px 50px;
    /* Width restriction if you want */
  }

  /* [VERTICAL LINE] */
  .vtl::before {
    content: '';
    width: 5px;
    background-color: rgb(126, 126, 126);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
  }


/* [ADD SPEECH BUBBLE TRIANGLE] */
div.ewrap::before {
    content: '';
    border: 10px solid transparent;
    border-right-color: #fff;
    border-left: 0;
    position: absolute;
    top: 40%;
    left: -10px;
  }

  /* [ADD CIRCLE ON VERTICAL LINE] */
  div.ewrap::after {
    content: '';
    background: #fff;
    border: 4px solid  $sem-blue-medium;
    width: 16px;
    height: 16px;
    border-radius: 20px;
    position: absolute;
    top: 40%;
    left: -41px;
  }

/* [EVENT WRAPPER] */
div.ewrap {
    padding: 10px 0;
    position: relative;
    width: 85%;
  }

div.ewrapHr {
  position: absolute;
  left: -41px;
  top: 40%;
  width: 100px;
  height: 10px;
  border-bottom: 4px solid $sem-blue-medium;
}
  /* [EVENT ITSELF] */
  div.event {
    padding: 8px 8px 0px;
    background-color: #fff;
    position: relative;
    border-radius: 4px;
    border: 2px solid  rgb(209, 209, 209);
  }

  /* [THE DATE & TEXT] */
  .date {
    font-size: 1.1em;
    color: #333;
  }
  p.text {
    margin-left: 25px;
  }
