.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.no-padding{
  padding: 0 !important;
}

.margin-10 {
  margin: 10px !important;
}

.margin-5 {
  margin: 5px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-top-2 {
  margin-top : 2px !important;
}

.margin-3 {
  margin: 3px !important;
}

.margin-top-3 {
  margin-top: 3px !important;
}

.margin-bottom-3 {
  margin-bottom: 3px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-left-3 {
  margin-left: 3px !important;
}

.margin-left-6 {
  margin-left: 6px !important;
}

.margin-right-3 {
  margin-right: 3px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-6 {
  margin-right: 6px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.padding-top-0{
  padding-top: 0px !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}

.padding-top-7 {
  padding-top: 7px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-left-0{
  padding-left: 0px !important;
}
.center-align {
  text-align: center!important;
}

.highcharts-credits{
  fill-opacity: 0 !important;
}

.inline-block{
  display: inline-block;
}

.line-height-1-5 {
  line-height: 1.5;
}

.overflow-x-scroll{
  overflow-x: auto;
}

.overflow-visible {
  overflow: visible !important;
}

.randomQuote-emoji {
  font-size: 42px;
}

.randomQuote-msg {
  font-size: 17px;
  font-weight: 700;
  color: black;
}

.small-text {
  font-size: 14px !important;
}

.userCircle {
  background-color: $blue;
  width: 37px;
  line-height: 37px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 18px;
}

.bounce {
  animation-name: bounce;
  animation-timing-function: ease;
  animation-duration: 3s
}

@keyframes bounce {
  0%   { transform: translateY(0); }
  17%  { transform: translateY(-10px); }
  34%  { transform: translateY(0); }
  51%  { transform: translateY(-10px); }
  68% { transform: translateY(0); }
  85%  { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}

g [aria-labelledby^='id']{display:none !important;}

.transparent-border {
  border-color: transparent !important;
}

.nowrap {
  white-space: nowrap;
}

.min-height-400 {
  min-height: 400px;
}

.min-height-80vh {
  min-height: 80vh;
}

.min-height-200 {
  min-height: 200px;
}

.min-height-300 {
  min-height: 300px;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.font-weight-normal{
  font-weight: normal !important;
}

.font-weight-bold{
  font-weight: bold !important;
}

.border-right{
  border-right: 1px solid #dee2e6!important;
}

.error-message-block {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #dc3545;
}


.whitespace-nowrap{
  white-space: nowrap;
}
