.companyTab {
  color: black;
  font-weight: 200;
  font-size: 0.9rem;
  line-height: 3rem;
  margin-right: 20px;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  &.active-tab {
    border-bottom: $blue solid 3px;
  }
  .noti-badge {
    position: relative;
    font-size: 0.7rem;
    top: -4px;
    left: 6px;
  }
}

.secondaryTabs {
  background-color: #fafafa;
}

.gm-style-iw.gm-style-iw-c {
  padding: 0 !important;
  .gm-style-iw-d {
    overflow: auto !important;
  }
  button[class*='gm-ui-'] {
    display: none !important;
  }
}
