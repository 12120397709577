.verticalTab {
  color: $gray-700;
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 6px;
  width: 100%;
  &.active-tab {
    border-right: $blue solid 3px;
    color: $blue;
  }
}

.borderRight {
  border-right: #eaeaea solid 1px;
}

.tableStyle {
  width: 100%;
  margin-bottom: 0;
  th,  td {
    padding: 0.85rem;
    padding-left: 0.45rem;
    padding-right: 0.25rem;
  }

  th:first-child,  td:first-child {
    padding-left: 1rem;
  }
  td {
    font-size: 0.8rem;
    padding-bottom: calc(0.6rem - 1px);
  }
  tr {
    border: 1px solid $gray-100;
  }
  thead {
    th {
      color: $gray-900;
      font-weight: 600;
      font-size: 0.9rem;
      border: 1px solid #f2f2f2;
    }
    th.filtered {
      border: 1px solid #e4ebf0;
      background-color: #e4ebf0;
      border-collapse: separate !important;
      border-left: 2px solid #02a8b5 !important;
    }
  }
  thead {
    background-color: $gray-300;
    tr {
      line-height: 0.9rem;
    }

  }
  tbody tr:hover td, tbody tr:hover th {
    background-color: $gray-light;
  }

}

.tableDevice {
  tr {
    border: 1px solid $gray-100;
  }
  thead {
    th {
      color: $gray-900;
      font-weight: 600;
      font-size: $h5-font-size;
    }
  }
  thead {
    background-color: $gray-300;
    tr {
      line-height: 0.9rem;
    }

  }
  tbody tr:hover td, tbody tr:hover th {
    background-color: $gray-light;
  }
  table-layout: fixed;
  th, td { overflow: hidden; }

}

.griddleIcons {
  font-weight: 300;
  font-size: 1rem;
  color: $link-color;
  text-align: left;
  padding-left: 0;
  i {
    font-size: 1.3rem;
    font-weight: 500;
  }
  li {
    float: left;
    list-style-type: none;
    min-width: 90px;
  }

}

.griddleTable {
  width: 100%;
  text-align: left;
  tr {
    border: 1px solid $gray-100;
    font-weight: 200;
    font-size: 1rem;
  }
  th, td {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: calc(0.5rem - 1px);
  }
  td {
    line-height: 2.2rem;
    font-weight: 600;
    font-size: $h5-font-size;
    color: $gray-700;
  }
  thead {
    line-height: 1.6rem;
    background-color: $gray-light;
    th {
      color: $gray-700;
      font-weight: 600;
      font-size: $h5-font-size;
    }
  }
  tbody tr:hover td, tbody tr:hover th, tbody tr:hover    {
    .griddleCol{
      color: $sem-turq-blue;
    }
    background-color: rgba(179, 179, 179, 0.05);
    }
}


.griddleCol{
  font-weight: 200;
  font-size: 1rem;
}

.niceSize{
  font-weight: 200;
  font-size: 0.98rem;
}

.highcharts-area {
  fill-opacity: 0.15;
  fill: $sem-turq-blue;
  stroke: 0;

}

.highcharts-markers {
  stroke-width: 1px;
  stroke: $sem-blue-dark;
}

.highcharts-navigator-mask-outside {
  fill-opacity: 0.2;
  fill: $gray-600;
}

.highcharts-navigator-mask-inside {
  fill-opacity: 0.2;
  fill:whitesmoke;
  cursor: ew-resize;
}

.highcharts-navigator-outline {
  stroke: #606063;
  fill: none;
}

.highcharts-navigator-handle {
  cursor: ew-resize;
}

.highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.025;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}

.newTable {
  text-align: left;
  height: 100%;
  //overflow: auto;
  //padding-bottom: 10px;
  table {
    //border: 1px solid #000;
    min-width: 100%;
    overflow-x: auto;
    font-size: 1rem;
    font-weight: 400;
    color: $gray-800;
    text-align: left;
    margin-bottom: 1px;
  }
  tr {

    line-height: 2rem;
    padding: 0;

      border: 1px solid $gray-100;

  }
  //tbody tr:nth-child(even):not(.subRow) {
  //  border-top: 1px solid #eceef0;
  //  background-color: #f8f8f8;
  //}
  td {
    font-size: 0.9rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.3rem;
    padding-bottom: calc(0.3rem - 1px);
    white-space: nowrap;
  }
  thead {
    line-height: 1.6rem;
    background-color: #eaeaea;

    th {
      font-weight: 400;
      font-size: 0.98rem;
      padding-left: 0.9rem;
      padding-right: 0.9rem;
      padding-top: 0.3rem;
      padding-bottom: calc(0.3rem - 1px);
    }
  }
  .tableIcons{
    color: $gray-800;
    font-size: 0.82rem;
  }

  .tableIcons-stalled{
    color: $gray-500;
    font-size: 0.82rem;
  }

  tbody tr:hover td, tbody tr:hover th, tbody tr:hover    {
    color: $sem-turq-blue;
    .tableIcons{
      color: $sem-turq-blue;
    }
  }
    font-weight: 300;
    font-size: 0.9rem;
    color: $link-color;
    text-align: left;
    padding-left: 0;
    i {
      font-size: 1rem;
      font-weight: 500;
    }
    li {
      float: left;
      list-style-type: none;
      margin-right: 10px;
    }
  }

.compactTable {
  text-align: left;
  table {
    //border: 1px solid #000;
    min-width: 100%;
    overflow-x: auto;
    font-size: 0.875rem;
    font-weight: 400;
    color: $gray-800;
    text-align: left;
    margin-bottom: 1px;
  }
  tr {
    border-top: 1px solid #eceef0;
    line-height: 2rem;
    padding: 0;
  }
  //tbody tr:nth-child(even):not(.subRow) {
  //  border-top: 1px solid #eceef0;
  //  background-color: #f8f8f8;
  //}
  td {
    font-size: 0.875rem;
    padding-left: .3rem;
    padding-right: .3rem;
    padding-top: 0.3rem;
    padding-bottom: calc(0.3rem - 1px);
    white-space: nowrap;
  }

  td:first-child {
    padding-left: 1rem;
  }

  thead {
    line-height: 1.6rem;
    background-color: #eaeaea;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    th {
      font-weight: 400;
      font-size: 0.875rem;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      padding-top: 0.3rem;
      padding-bottom: calc(0.3rem - 1px);
    }
    th:first-child {
      padding-left: 1rem;
    }
  }
  .tableIcons{
    color: $gray-800;
  }

  .tableIcons-stalled{
    color: $gray-700;
  }

  tbody tr:hover td, tbody tr:hover th, tbody tr:hover    {
    color: $sem-turq-blue;
    .tableIcons{
      color: $sem-turq-blue;
    }
  }
  font-weight: 300;
  font-size: 0.875rem;
  color: $link-color;
  text-align: left;
  padding-left: 0;
  i {
    font-size: 0.875rem;
    font-weight: 500;
  }
  li {
    float: left;
    list-style-type: none;
    margin-right: 10px;
  }
}

.expandedRow {
    table {
      border: none;
    }
    tr {
      border: none;
      padding:0;
      background-color: #f8f8f8;
    }
    th, td {
      padding:0;
    }
    thead {
      display: none;
    }
    tbody tr:hover td, tbody tr:hover th, tbody tr:hover { }
}

.subRow {
  color: $gray-100;
  table {
    border: none;
    min-width: 100%;
    overflow-x: auto;
    font-size: 1rem;
    font-weight: 200;
    color: $gray-400;
    text-align: left;
  }
  tr {
    background-color: #fbfbfb;
    color: $gray-800;
  }
  th, td {
    color: $gray-800;
    padding-left: 1.5rem;
    padding-right: 1rem;
    padding-top: 0.3rem;
    padding-bottom: calc(0.3rem - 1px);
  }
  thead {
    display: none;
  }
  tbody tr, tr:hover td, tbody tr:hover th, tbody tr:hover {

  }
  a, a.tableIcons {
    color: $gray-700;
  }

}

tr.subRow {
  background-color: #f6f8f8;
  color: $gray-100;
  font-size: 1rem;
  font-weight: 200;
  text-align: left;
  th, td {
    padding-left: 1rem;
  }
}


.eventsTable {
  text-align: left;
  table {
    border: 1px solid #000;
    min-width: 100%;
    overflow-x: auto;
    font-size: 0.9rem;
    font-weight: 400;
    color: $gray-800;
    text-align: left;
    max-height: 50vh;
  }
  tr {
    border: 1px solid $gray-100;
    line-height: 1.4rem;
  }
  th, td {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: calc(0.5rem - 1px);
  }
  tbody tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  thead {
    line-height: 0.9rem;
    background-color: $gray-light;
    th {
      color: $gray-700;
      font-weight: 400;
      font-size: 1rem;
    }
  }

  tbody tr:hover td, tbody tr:hover th, tbody tr:hover    {
    color: $sem-turq-blue;
    background-color: rgba(179, 179, 179, 0.05);
    .tableIcons{
      color: $sem-turq-blue;
    }
  }
    font-weight: 300;
    font-size: 1rem;
    color: $link-color;
    text-align: left;
    padding-left: 0;
    i {
      font-size: 1.3rem;
      font-weight: 500;
    }
    li {
      float: left;
      list-style-type: none;
      min-width: 90px;
    }
  }

  .gauge-dark {
    background: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $sem-blue-dark;
    margin-top:10px;
  }

  .space{
    margin-top:12px;
  }

  .value-text {
    color: $sem-blue-dark;
  }

  .statistics-card {
    background-color: #eaeaea;
    padding-top: 20px;
    padding-bottom: 18px;
    color: white;
  }

  .statistics {
    background-color: #fff;
    margin-top:5px;
    padding-top: 6px;
    padding-bottom: 8px;
    color: $sem-blue-dark;
  }

  .stats-improve {
    background-color: #fff;
    //box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    //transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    //margin-top: 10px;
    //margin-bottom: 10px;
    padding: 6px 14px 14px 14px;
    text-align: left;
    &:hover {
      //box-shadow: 0 1px 3px rgba(108, 80, 80, 0.7), 0 1px 2px rgba(0,0,0,0.4);
      background-color: rgb(247, 247, 247);
    }
  }

  .stats-improve.active {
    background-color: rgb(247, 247, 247);
  }

  .asset-properties {
    max-height: 50vh;
    overflow-y: scroll;
  }

  .common-border {
    border: solid 1px rgb(184, 185, 185);
  }
  .common-border-right {
    border-right: solid 1px rgb(184, 185, 185);
  }

  .common-border-bottom {
    border-bottom: solid 1px rgb(184, 185, 185);
  }

  .common-border-top {
    border-top: solid 1px rgb(184, 185, 185);
  }

  .stats-icon {
    font-size: 2rem;
    text-align: left;
    color: $gray-600;

  }

  .stats-icon-color{
    color: $gray-600;
  }

  .stats-text {
    text-align: right;
    margin-top: 18px;
    padding-right:12px;
    color: $gray-700;
  }

  .stats-factors {
    padding-left:4px;
    text-align: left;
    color: $gray-700;
  }

  .stats-blank {
    margin:4px;
    padding: 10px;
    text-align: right;
  }



  .ImgAnno {
    padding:30px;
  }

  .statistics-light {
    background: #fafafa;
    padding-top: 20px;
    padding-bottom: 18px;
    color: $sem-blue-dark;
  }

  .color-a {
    color: #064e40;
  }

  .color-b {
    color: #0dad8d;
  }

  .color-c {
    color: #8dd8cc;
  }

  .color-d {
    color: #30bfbf;
  }

  .color-e {
    color: #0c98ba;
  }

  .color-f {
    color: #1164b4;
  }


  .stats-card {
    border: solid 1px $gray-400;
  }

  .clear-value {
    background-color: $gray-300;
    color: $sem-blue-dark;
    border: solid 1px $gray-500;
    margin-left: auto;
    margin-right: auto;
    padding: 6px 0 6px 0 ;
    text-align: center;
  }

  .white-text {
    color: $gray-800;
  }

  .white-text {
    color: $gray-800;
  }

  .table-sort {
    position: relative;
    left: 4px;
  }

  .error-page {
    text-align: center;
    padding-top: 200px;
  }

.alarmTable {
  text-align: left;
  table {
    min-width: 100%;
    overflow-x: auto;
    font-size: 0.8rem;
    font-weight: 300;
    color: $gray-800;
    text-align: left;
  }
  tr {
    line-height: 2rem;
    padding: 0;
  }
  tbody tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  td {
    font-size: 0.88rem;
    padding-left: 1rem;
    padding-top: 0.3rem;
    padding-bottom: calc(0.3rem - 1px);
  }
  thead {
    line-height: 1.6rem;
    background-color: #eaeaea;

    th {
    font-size: 0.94rem;
    font-weight: 300;
      padding-left: 0.9rem;
      padding-right: 0.9rem;
      padding-top: 0.3rem;
      padding-bottom: calc(0.3rem - 1px);
    }
  }
}


.headerTable {
  text-align: left;
  table {
    min-width: 100%;
    overflow-x: auto;
    font-size: 0.8rem;
    font-weight: 300;
    color: $gray-800;
    text-align: left;
  }
  tr {
    line-height: 2rem;
    padding: 0;
  }
  tbody tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  td {
    font-size: 0.88rem;
    padding-left: 1rem;
    padding-top: 0.3rem;
    padding-bottom: calc(0.3rem - 1px);
  }
  thead {
    line-height: 1.6rem;
    background-color: #eaeaea;

    th {
      font-size: 0.94rem;
      font-weight: 300;
      padding-left: 0.9rem;
      padding-right: 0.9rem;
      padding-top: 0.3rem;
      padding-bottom: calc(0.3rem - 1px);
    }
  }
}

.profile-pic {
  height: 1.5rem;
  margin-right: 6px;
}

.label-size {
  width: 80px;
  text-align: center;
}

.label-size-90 {
  width: 90px;
  text-align: center;
}

.label-ack {
  width: 140px;
}
