// 
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

.bg-pattern {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5ac6d2+1,0a5266+50,040a2b+80,040a2b+87,000000+100&1+0,0.96+0,0.96+68 */
    background: -moz-linear-gradient(-45deg, rgba(90,198,210,0.96) 0%, rgba(90,198,210,0.96) 1%, rgba(10,82,102,0.96) 50%, rgba(6,39,67,0.96) 68%, rgba(4,10,43,0.96) 80%, rgba(4,10,43,0.96) 87%, rgba(0,0,0,0.96) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(90,198,210,0.96) 0%,rgba(90,198,210,0.96) 1%,rgba(10,82,102,0.96) 50%,rgba(6,39,67,0.96) 68%,rgba(4,10,43,0.96) 80%,rgba(4,10,43,0.96) 87%,rgba(0,0,0,0.96) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(90,198,210,0.96) 0%,rgba(90,198,210,0.96) 1%,rgba(10,82,102,0.96) 50%,rgba(6,39,67,0.96) 68%,rgba(4,10,43,0.96) 80%,rgba(4,10,43,0.96) 87%,rgba(0,0,0,0.96) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f55ac6d2', endColorstr='#f5000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

// authentication pages background
body.authentication-bg {
    background-color: $gray-200;
    background-size: cover;
    background-position: center;
}

body.authentication-bg-pattern {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5ac6d2+1,0a5266+50,040a2b+80,040a2b+87,000000+100&1+0,0.96+0,0.96+68 */
    background: -moz-linear-gradient(-45deg, rgba(90,198,210,0.96) 0%, rgba(90,198,210,0.96) 1%, rgba(10,82,102,0.96) 50%, rgba(6,39,67,0.96) 68%, rgba(4,10,43,0.96) 80%, rgba(4,10,43,0.96) 87%, rgba(0,0,0,0.96) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(90,198,210,0.96) 0%,rgba(90,198,210,0.96) 1%,rgba(10,82,102,0.96) 50%,rgba(6,39,67,0.96) 68%,rgba(4,10,43,0.96) 80%,rgba(4,10,43,0.96) 87%,rgba(0,0,0,0.96) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(90,198,210,0.96) 0%,rgba(90,198,210,0.96) 1%,rgba(10,82,102,0.96) 50%,rgba(6,39,67,0.96) 68%,rgba(4,10,43,0.96) 80%,rgba(4,10,43,0.96) 87%,rgba(0,0,0,0.96) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f55ac6d2', endColorstr='#f5000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

// Logout page
.logout-icon {
    width: 140px;
}

.auth-logo {
    padding-top: 0.75rem;
    padding-bottom: 2rem;
}