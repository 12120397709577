//
// tables.scss
//


//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }

  tr {
    td {
      a {
        color: inherit;
      }
    }
  }
}

.table {
    td.action-col {
        padding: .1rem !important;
    }
  > :not(:first-child){
    border-top: 0;
      //2px solid  $gray-300;
  }
}
.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
   // padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}

.detailTable {
  background-color: #fafafa;
  text-align: left;
  padding: 5px;
  border-bottom: 2px solid  $gray-300;
  table {
    min-width: 100%;
    overflow-x: auto;
    font-size:  .85rem;
    //font-weight: 300;
    color: $gray-700;
    text-align: left;
  }
  tr {
    line-height: 1.6rem;
    padding: 0;
  }
  tbody tr:nth-child(even) {
    //background-color: #f8f8f8;
  }

  td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: 0.3rem;
    padding-bottom: calc(0.3rem - 1px);
    //background-color: #eaeaea;
  }

  td:first-child {
    padding-left: 1rem;
    padding-right: 0.25rem;
    padding-top: 0.3rem;
    padding-bottom: calc(0.3rem - 1px);
    //background-color: #eaeaea;
  }
  thead {
    line-height: 1.6rem;
    color: $gray-700;
    th {
      font-weight: 600;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      padding-top: 0.3rem;
      padding-bottom: calc(0.3rem - 1px);
      vertical-align: bottom;
    }

    th:first-child {
      font-weight: 600;
      padding-left: 1rem;
      padding-right: 0.25rem;
      padding-top: 0.3rem;
      padding-bottom: calc(0.3rem - 1px);
    }
  }
}

.table-striped > tbody > tr:nth-of-type(odd) > *{
  color: inherit;
}
