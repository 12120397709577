//
// accordions.scss
//

.custom-accordion {
    .card {
        + .card {
            margin-top: 0.5rem;
        }
    }
    .accordion-arrow {
        font-size: 1.2rem;
    }
    a {
        &.collapsed {
            i.accordion-arrow {
                &:before {
                    content: "\F142";
                }
            }
        }
    }
}

.accordion {
  --bs-accordion-active-color: #343a40 !important;
  --bs-accordion-active-bg: transparent !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-btn-focus-border-color: none !important;
}

.accordion-button {
  padding-left: 0px !important;
  font-size: 0.89rem !important;
  font-weight: 500 !important;
}

.accordion-body{
  padding-left: 0px !important;
}
