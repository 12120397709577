.device-journey{
  animation: animate-base-container 850ms forwards;
  .nav.nav-tabs{
    border: 0px solid transparent;
    background-color: #5b5c5b;
    .nav-item {
        color: white;
        cursor: pointer;
        background-color: transparent;
        border: 0px solid transparent;
        &:hover,
        &:focus {
          background-color: transparent;
          border-color: transparent;
        }
        .nav-link{
          color: white;
          cursor: pointer;
          border-bottom: 4px solid transparent;
          &:hover,
          &:focus {
            background-color: transparent;
            border-color: transparent;
          }
        }
        .nav-link.active {
          color: white;
          border: 1px solid gray;
          border-radius: 0px;
          background-color: transparent;
          border-bottom: 4px solid #01beda;
        }
      }
  }

  .map-container{
    height: 500px;
    position: relative;
  }
}

.journey-filter{
  position: absolute;
  background-color: white;
  padding: 3px;
  padding-left:16px;
  padding-right:16px;
  z-index: 99;
  top: 10px;
  left: 186px;
  line-height: 1.5rem;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
  -ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
  -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
  &:hover,
  &:focus {

  }
}

.journey-loader{
  background-color: white;
  text-align: center;
  z-index: 99;
  width: 50rem;
  margin: 2rem auto auto;
}

.journey-box{
  position: relative;
}

.map-box{
  position: relative;
  height: calc(100vh - 50px);
  min-height: 400px;
  width: 100%;
  margin-top: 1px;
}


.zone-map-box {
  margin-top: 8px;
  position: relative;
  height: 100%;
  width: 100%;
}

.timeline-filter{
  margin-left: 5vw;
  margin-right: 5vw;
  position: absolute;
  z-index: 99;
  bottom: 40px;
  width:80%;
  background-color: white;
  border-radius: 8px;
  padding-top: 6px;
  padding-left: 6px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
  -ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
  -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
}

.chart-box{
  width: 100%;
}
