.chart-rc-slider {
  margin-left: 52px;

  .rc-slider-mark-text, .rc-slider-mark-text-active {
    height: 24px;
    width: 24px;
    color: #fff !important;
    font-family: "Material Design Icons";
    font-size: 11px;
    background-size: cover;
    margin-top: -98px;
    transition: margin 700ms;
  }

  .slider-mark-container {
    > span {
      position: absolute;
      left: 6px;
    }
  }

  &.minimized {
    .rc-slider-mark-text, .rc-slider-mark-text-active {
      margin-top: -35px;
    }
  }
}
