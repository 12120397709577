//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 60px;
  overflow-x: hidden;
  line-height: 1.3;
}

a {
  outline: 0;
}

.card-new {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  animation : animate-grow-container 850ms forwards;
    // animate-base-container 850ms forwards;
  //margin-bottom: -15px;
}

.card-new-header {
  background-color: #eaeaea;
  font-weight: 400;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.4rem;
  padding-bottom: 0.5rem;
  color: $sem-blue-dark;
}

.card-slim-header {
  background-color: #f7f7f7;
  font-weight: 400;
  font-size: 1.1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  color: $sem-blue-dark;
}

.card-header-clean {
  border-bottom: 1px solid rgb(220, 223, 223);
  font-weight: 200;
  font-size: 0.95rem;

  padding-top: 0.4rem;
  padding-bottom: 0.5rem;
  color: $sem-blue-dark;
}

.card-new-content {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.card-new-content2 {
  padding-left: 0.8rem;
  padding-right: 0.9rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}


.card-content-header {
  font-size: 0.95rem;
  margin-bottom: 10px;
  padding-left: 5px;
  color: $sem-blue-dark;
  text-align: left;
}
