//
// reboot.scss
//


// Forms
label {
  font-weight: $font-weight-semibold;
}


button:focus {
  outline: none;
}

a {
  text-decoration: none;
}

i.icon {
  text-decoration: none;
}


.input-group-text{
  background-color: #f2f2f2;
}
.form-check-input{
  margin-right: 5px;
}

