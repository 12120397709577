
.spotlight-overlay {
  background-color:  rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.spotlight-bar {
  top: 30%;
  left: 50%;
  width: 680px;
  margin-left: -340px;
  z-index: 100;
  font-size: 12px;
  overflow: hidden;
  border-radius: 6px;
  position: fixed;
  letter-spacing: 0.3px;
  font-family: Verdana, "Lucida Sans Unicode", sans-serif;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.close {
  position: absolute;
  right: 12px;
  top: 8px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  border: none;
  font-size: 28px;
}

.close:hover{
  opacity: 1;
}
