.rc-tree{
  list-style-type: none !important;
  max-height: 80vh;
  overflow: auto;

  ul {
    padding-inline-start: 20px;

    li {
      list-style-type: none !important;
      cursor: pointer;
    }

    li.leaf-node {
      border: 1px solid inherit;
      padding: 3px;
      border-radius: 3px;
    }

    li.active-selected-node {
      border: 1px solid #0C6FB8 !important;
      padding: 3px;
      border-radius: 3px;
    }
    .rc-tree-treenode-selected {
      .rc-tree-title {
      }
    }
    .rc-tree-node-selected {
      background: transparent;
      border: none;
      opacity: 1;
    }
  }
  .rc-tree-child-tree {
    list-style-type: none !important;
    cursor: pointer;
  }
}
