@keyframes animate-grow-container {
  from {
    opacity: 0;
    transform: scale(.95)
  }
  to {
    opacity: 1;
    transform: scale(1)
  }
}
