.rw-widget-picker {
  min-height: 33px;
}

.rw-time-input.rw-widget-input.rw-widget {
  > input {
    padding: 0 0.2em;
  }
  .rw-time-part-meridiem {
    text-transform: inherit;
    padding: 0;
    padding-left: 0.2em;
    padding-right: 0.5em;
  }
}
